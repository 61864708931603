<template>
  <div>
    <Nav navType="fun_text" textCon="商家榜" :textColor="textColor" :sty2="sty">
      <template v-slot:rightText>
        <span class="iconfont iconzhuanfa" style="color:#fff;font-size:20px"></span>
      </template>
    </Nav>
    <div class="bgimg">
      <h1>商家排行榜</h1>
      <div>商家榜  按月售销量+店铺评价排序·每天00:00更新</div>
    </div>
    <RankList :listData="listData" type="store"></RankList>
  </div>
</template>
<script>
import Nav from "@components/public/searchTop";
import RankList from "./RankList";
import {storeRankList} from "./mydata"
export default {
  data(){
    return{
      textColor:{
        color:"#fff",
      },
      sty:{
        background:"rgba(0,0,0,0)"
      },
      listData:[],
    }
  },
  mounted(){
    this.testfn()
  },
  methods:{
    testfn(){
      this.listData = storeRankList
    },
  },
  components:{
    Nav,
    RankList
  }
}
</script>
<style lang="scss" scoped>
.bgimg{
  width:100%;
  height: 150px;
  background: url("https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=4041845150,102448655&fm=26&gp=0.jpg") no-repeat 100% 100%;
  color:#fff;
  text-align: center;
  padding-top:65px;
  h1{
    font-size: 30px;
    margin-bottom: 12px;
  }
  div{
    width:74%;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    margin:0 auto;
    border-radius: 10px;
    background: rgba($color: #fff, $alpha: 0.3)
  }
}
</style>
<template>
  <div class="shop-list">
    <Tabs v-model="active" sticky v-if="showTabs" >
      <Tab  v-for="(item, idx) in cateDate" :key="idx">
        <div class="tab-title" slot="title" @click="send(item.id)">{{ item.name }}</div>
      </Tab>
    </Tabs>
    <div class="gray-line"></div>
    <div class="list-item" v-for="(item,index) in listData" :key="index">
      <div class="img">
        <img :src="item.image[0].url" alt="">
        <div class="rank" v-if="index + 1 <=3">{{index + 1 }}</div>
        <div class="rank rankcolor" v-else>{{index + 1 }}</div>
        <div class="sales" v-if="type == 'hot'">月售{{item.sale_num}}</div>
      </div>
      <!-- 热销商品列表样式 -->
      <div class="infor" v-if="type == 'hot'">
        <h1>{{item.name}}</h1>
        <p>
          <!-- <span class="name">{{item.name}}</span>
          <span class="address">{{item.address}} &nbsp; | &nbsp; {{item.distance}}</span> -->
        </p>
        <div class="btm now_price">￥<span>{{item.price}}</span></div>
        <div class="btm ori_price">￥{{item.mktprice}}</div>
        <div class="btm gotobuy">去购买</div>
      </div>
      <!-- 商家列表样式 -->
      <div class="infor" v-if="type == 'store'">
        <h1>{{item.title}}</h1>
        <div class="rate-line">
          <rate
            v-model="item.star"
            allow-half
            size="11px"
            void-icon="star"
            void-color="#eee"
            color="#ff6600"
            readonly
          />
          <span class="score">{{item.star == 0? '暂无评分':item.star}}</span>
          <span class="sale">月售 {{item.sales}}</span>
        </div>
        <div class="type-line">
          <span>{{item.type}}&nbsp;|&nbsp;</span>
          <span>{{item.address}}&nbsp;|&nbsp;</span>
          <span>{{item.distance}}</span>
        </div>
        <div class="labels">
          <span class="label-item" v-for="(name,index) in item.labels" :key="index">{{name}}</span>
        </div>
      </div>
      <div class="gotobutton" v-if="type == 'store'">
        <div class="button">进店逛逛</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs,Rate } from "vant";
export default {
  components: {
    Tab,
    Tabs,
    Rate
  },
  props: {
    showTabs: {
      type: Boolean,
      default: true
    },
    listData: {
      type: Array
    },
    cateDate: {
      type: Array,
      default: function() {
        return []
      },
    },
    type:{
      type:String,
      default:'hot'
    },
    cateFunction:{
      type: Function,
    }
  },
  data() {
    return {
      active: 0
    };
  },
  methods: {
    send(id){
      console.log("点击事件", id);
      this.cateFunction(id);
    },
  }
};
</script>
<style lang="scss" scoped>
.gray-line{
  width:100%;
  height: 6px;
  background-color: #f4f4f4;
}
.list-item{
  width:92%;
  height: 93px;
  margin: 0 auto;
  border-top: 1px solid #f4f4f4;
  display: flex;
  padding-top: 10px;
  .img{
    width:72px;
    height: 72px;
    position: relative;
    border-radius: 5px;
    border:1px solid #f4f4f4;
    margin-right: 10px;
    color:#fff;
    img{
      width:100%;
      height: 100%;
      border-radius: 5px;
    }
    .rank{
      width: 16px;
      height: 18px;
      line-height: 16px;
      text-align: center;
      border-bottom-left-radius:8px;
      border-bottom-right-radius:8px;
      position: absolute;
      top:0;
      left:3px;
      background: #ff6600
    }
    .rankcolor{
      background: #999;
    }
    .sales{
      position: absolute;
      bottom:0;
      width:100%;
      height: 15px;
      font-size: 10px;
      text-align: center;
      background: rgba($color: #000000, $alpha: 0.6);
      border-bottom-left-radius:5px;
      border-bottom-right-radius:5px;
    }
  }
  .infor{
    flex:1;
    height: 73px;
    position: relative;
    h1{
      margin-top:2px;
      font-size: 14px;
      margin-bottom: 3px;
      color:#333;
    }
    p{
      margin-top:5px;
      color:#8c8c8c;
      font-size: 11px;
      clear: both;
      .name{
        width:45%;
      }
      .address{
        float:right;
      }
    }
    .btm{
      position: absolute;
      font-size: 11px;
      display: inline-block;
      padding: 0 5px;

    }
    .now_price{
      bottom:0px;
      left: 0;
      color:#ff6600;
      span{
        font-size: 16px;
      }
    }
    .ori_price{
      bottom:2px;
      left: 60px;
      color:#8c8c8c;
      text-decoration: line-through;
    }
    .gotobuy{
      width:53px;
      height: 20px;
      line-height: 20px;
      bottom: 0;
      right: 0;
      background:linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
      border-radius: 5px;
      color:#fff;
      text-align: center;
    }
    .rate-line{
      font-size: 10px;
      line-height: 10px;
      margin-bottom: 3px;
      .score{
        color:#ff6600;
        margin-left: 4px;
      }
      .sale{
        margin-left: 11px;
        color:#000;
      }
    }
    .type-line{
      color:#8c8c8c;
      font-size: 11px;
      margin-bottom: 4px;
    }
    .labels{
      font-size: 9px;
      color:#ff6600;
      span{
        background: #FFEEC6;
        padding:1px 2px;
        margin-right: 5px;
      }
    }
  }
  .gotobutton{
    width:53px;
    height: 73px;
    padding-top:25px;
    .button{
      width:53px;
      height: 20px;
      background:linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
      text-align: center;
      font-size: 11px;
      color:#fff;
      line-height: 20px;
      border-radius: 5px;
    }
  }
}
</style>
